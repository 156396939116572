<template>
    <v-container>
        <div v-if="mode && !['edit', 'create'].includes(mode)" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form :readonly="readOnly">
            <v-checkbox
                v-model="team.available"
                label="Available"
            />
            <v-text-field
                v-model="team.coordinates"
                label="Coordinates"
                :clearable="clearable"
            />
            <v-text-field
                v-model="team.title"
                :rules="rules"
                label="Title"
                :clearable="clearable"
            />
            <v-text-field
                v-model="team.address"
                label="Address"
                :clearable="clearable"
            />
            <div class="description-editor">
                <span class="description-label">Description</span>
                <editor
                    v-model="team.description"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
            <v-text-field
                v-model="team.titleEn"
                label="Title in English"
                :clearable="clearable"
            />
            <v-text-field
                v-model="team.addressEn"
                label="Address in English"
                :clearable="clearable"
            />
            <v-autocomplete
                v-model="team.dealer"
                :items="dealers"
                name="Dealer"
                :item-text="getDealerText"
                return-object
                label="Dealers"
                clearable
            />

            <div class="description-editor">
                <span class="description-label">Description in English</span>
                <editor
                    v-model="team.descriptionEn"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
            <v-file-input
                v-if="mode !== 'show'"
                v-model="newImage"
                label="Image input"
                filled
                show-size
                prepend-icon="mdi-camera"
                @change="createNewImagePreview"
            />
            <div class="image-input-wrapper">
                <div v-if="previewImage && !previewImage.shouldDelete" class="image-input" :class="{'green-border': !previewImage.id}">
                    <div v-if="mode === 'edit'" class="delete-btn">
                        <v-btn class="mx-2 white--text" small color="red" @click="onImageDelete()">
                            X
                        </v-btn>
                    </div>
                    <v-img v-if="previewImage.url" class="img-preview" :src="previewImage.url" />
                    <v-text-field
                        v-model="previewImage.description"
                        label="Image Description"
                        :clearable="clearable"
                    />
                </div>
            </div>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" large :disabled="shouldDisableSubmit()" color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import imageService from '@/services/image-service'
    import teamService from '@/services/team-service'
    import dealerService from '@/services/dealer-service'
    import Editor from '@tinymce/tinymce-vue'
    import store from '@/store'

    export default {
        name: 'TeamForm',
        components: { Editor },
        data: () => {
            return {
                mode: null,
                readOnly: false,
                clearable: true,
                availbale: null,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN,
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
                team: {
                    title: '',
                    description: '',
                    available: false,
                    address: '',
                    coordinates: '',
                    titleEn: '',
                    descriptionEn: '',
                    addressEn: '',
                    approved: true,
                    dealer: null
                },
                editorInit: {
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks fullscreen emoticons',
                        'insertdatetime media table paste help wordcount'
                    ],
                    fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | fontselect fontsizeselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image'
                },
                teamId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                newImage: null,
                previewImage: null,
                dealers: []
            }
        },
        computed: {
            dealerId() {
                return store.getters.currentUser && store.getters.currentUser.role === 'dealer' && store.getters.currentUser.dealerId
            }
        },
        created() {
            this.mode = this.$route.params.mode
            this.fetchAllDealers()
            if (this.mode === 'create') {
                return
            } else if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
                this.editorInit.setup = function(editor) {
                    editor.setMode('readonly')
                }
            }
            this.teamId = this.$route.params.teamId
            if (this.teamId) {
                teamService.getTeamById(this.teamId).then(resp => {
                    this.team = resp.data.team
                    this.previewImage = this.team.image
                    if (this.previewImage) {
                        this.previewImage.url = this.apiUrl + '/uploads/images/' + this.previewImage.imageName
                    }
                })
            }
        },
        methods: {
            getDealerText(dealer) {
                return `${dealer.firstName} ${dealer.lastName}`
            },
            fetchAllDealers() {
                dealerService.getAllDealers().then(resp => {
                    this.dealers = resp.data.data
                })
            },
            createNewImagePreview() {
                this.previewImage = this.newImage
                this.previewImage.url = URL.createObjectURL(this.newImage)
                this.previewImage.description = null
            },
            async onSubmit() {
                this.prepareIfDealerExists()
                if (this.mode === 'edit') {
                    this.editTeam()
                } else if (this.mode === 'create') {
                    this.createTeam()
                }
                this.deleteTeamImage()
                this.editImageDescriptions()
            },
            prepareIfDealerExists() {
                const dealerId = this.dealerId
                if (dealerId) {
                    this.team.dealer = dealerId
                    this.team.approved = false
                } else if (this.team.dealer) {
                    this.team.dealer = this.team.dealer.id
                } else {
                    this.team.dealer = null
                }
            },
            deleteTeamImage() {
                if (this.previewImage && this.previewImage.id && this.previewImage.shouldDelete) {
                    imageService.deleteImageById(this.previewImage.id)
                    this.previewImage = null
                }
            },
            editImageDescriptions() {
                if (this.previewImage && this.previewImage.id) {
                    let formData = new FormData()
                    formData.append('partnerId', this.partner)
                    if (this.previewImage.description) {
                        formData.append('description', this.previewImage.description)
                    }
                    imageService.editImageById(this.previewImage.id, formData)
                }
            },
            async createTeam() {
                const response = await teamService.createTeam(this.team)
                if (response.data.id) {
                    this.teamId = response.data.id
                    this.uploadImage()
                }
                this.$router.push(`/teams?page=${this.$route.query.page}`)
            },
            async editTeam() {
                await teamService.editTeamById(this.teamId, this.team)
                this.uploadImage()
                this.$router.push(`/teams?page=${this.$route.query.page}`)
            },
            shouldDisableSubmit() {
                return !this.team.title
            },
            onEdit() {
                this.$router.push(`/teams/${this.teamId}/edit?page=${this.$route.query.page}`)
            },
            uploadImage() {
                if (!this.newImage || (this.newImage && this.newImage.id)) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.newImage)
                formData.append('teamId', this.teamId)
                if (this.newImage.description) {
                    formData.append('description', this.newImage.description)
                }
                imageService.uploadNewImage(formData)
            },
            onImageDelete(previewImage) {
                if (this.previewImage.id) {
                    this.previewImage.shouldDelete = true
                    this.previewImage.url = null
                    this.previewImage = { ...this.previewImage }
                } else {
                    this.newImage = null
                }
            }
        }
    }
</script>

<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.description-label {
    color: rgba(0, 0, 0, 0.6);
}
.description-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
