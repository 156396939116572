import paronApi from '@/utils/paron-api'
export default {
    uploadNewImage(formData) {
        return paronApi.uploadFile('image/upload', formData)
    },
    uploadNewArticleImage(formData) {
        return paronApi.uploadFile('image/article/upload', formData)
    },
    getImageById(id) {
        return paronApi.get(`image?id=${id}`)
    },
    editImageById(id, formData) {
        return paronApi.post(`image/${id}/edit`, formData)
    },
    deleteImageById(id) {
        return paronApi.delete(`image/${id}`)
    }

}
